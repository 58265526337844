@import "@abgov/web-components/index.css";

/* --------------------------------------------------------- */

.pageHeading {
  padding: 10px 0px 10px 0px;
  font-weight: 400;
  font-size: 26px;
}

.content-padding {
  overflow: auto;
  padding: 50px 110px 110px 110px;

  &.home-page-content-padding {
    padding: 0px 110px 110px 0px;
    margin-top: -40px;
  }
}

.home-page-container {
  margin-left: 100px;
}

.home-page-workflow-label {
  margin-top: 50px;
  font-size: 24px;
  font-weight: bold;
}

.home-page-location-dropdown {
  margin-top: 50px;
  margin-bottom: 50px;
  width: 500px;
}

.logout {
  margin-left: 25px;
}

.workorder-main-tab-wrapper {
  pointer-events: initial;
  opacity: initial;

  &.disabled {
    pointer-events: none;
    opacity: 0.8;
  }
}

.workorder-parts-labour-tab-wrapper {
  pointer-events: initial;
  opacity: initial;

  &.disabled {
    pointer-events: none;
    opacity: 0.8;
  }

  margin-top: 50px;
}

.workorder-status {
  pointer-events: initial;
  opacity: initial;

  &.disabled {
    pointer-events: none;
    opacity: 0.8;
  }
}

.workorder-print {
  float: inline-end;
}

.form-item-textarea {
  box-sizing: border-box;
  outline: none;
  transition: box-shadow 0.1s ease-in;
  border: 1px solid var(--goa-color-greyscale-700);
  border-radius: var(--goa-border-radius-m);
  display: inline-flex;
  align-items: stretch;
  vertical-align: middle;
  background-color: var(--goa-color-greyscale-white);
  resize: none;

  &.workorder-comments {
    width: 700px;
  }

  &.workorder-problem {
    width: 700px;
  }

  &.workorder-subletdesc {
    width: 844px;
  }

  &:hover {
    border-color: var(--goa-color-interactive-hover);
    box-shadow: 0 0 0 var(--goa-border-width-m) var(--goa-color-interactive-hover);
  }

  &:focus {
    box-shadow: 0 0 0 3px var(--goa-color-interactive-focus);
  }
}

.goa-scrollable {
  height: 500px;
}

#workorder-tab-container {
  .div[role="tablist"] {
    display: inline-block;
  }
}

.import-workorder-header {
  position: sticky;
  top: 0;
}

.workorder-char-count-label {
  float: right;
  font-size: smaller;
}

.workorder-content-padding {
  padding: 50px 110px 30px 120px;
}

.workorder-tab-container {
  padding: 50px 110px 110px 130px;
  align-content: flex-start;
}

workby-dateout .create-work-order-header {
  padding-left: 10px;
}

.create-work-order-table {
  max-width: 900px;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 23% 26% 22% 24%;

  &.disable {
    pointer-events: none;
    opacity: 0.6;
  }
}

.work-order-problem {
  display: grid;
  grid-template-columns: 95% 5%;

  .work-order-problem-edit {
    text-align: end;
  }
}

.workorder-comments-container {
  &.disable {
    pointer-events: none;
    opacity: 0.6;
  }
}

.work-order-comment {
  display: grid;
  grid-template-columns: 95% 5%;

  &.disabled {
    pointer-events: none;
    opacity: 0.8;
  }

  .work-order-comment-edit {
    text-align: end;
  }
}

.workorder-unit-info-container {
  max-width: 1338px;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 20px;

  .unit-info-unitno {
    grid-row: 1;
    grid-column: 1;
  }

  &.disable {
    pointer-events: none;
    opacity: 0.8;
  }

  .unit-info-itemname {
    grid-row: 1;
    grid-column: 2;
  }

  .unit-info-make {
    grid-row: 2;
    grid-column: 1;
  }

  .unit-info-model {
    grid-row: 2;
    grid-column: 2;
  }

  .unit-info-serialno {
    grid-row: 2;
    grid-column: 3;
  }

  .unit-info-totalcost {
    grid-row: 3;
    grid-column: 1;
  }

  .unit-info-totalhours {
    grid-row: 3;
    grid-column: 2;
  }

}

.workorder-finance-container {
  max-width: 1338px;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 20px;

  &.disable {
    pointer-events: none;
    opacity: 0.8;
  }

  .finance-contactname {
    grid-row: 1;
    grid-column: 1;
  }

  .finance-contactphone {
    grid-row: 1;
    grid-column: 2;
  }

  .finance-expenditureofficer {
    grid-row: 1;
    grid-column: 3;
  }

  .finance-costcenter {
    grid-row: 2;
    grid-column: 1;

    &.hide {
      display: none;
    }
  }

  .finance-wbsnumber {
    grid-row: 2;
    grid-column: 2;

    &.hide {
      display: none;
    }
  }

  .finance-chargecode {
    grid-row: 2;
    grid-column: 1;
  }

  .finance-glaccount {
    grid-row: 3;
    grid-column: 1;

    &.hide {
      display: none;
    }
  }

  .finance-fund {
    grid-row: 3;
    grid-column: 2;

    &.hide {
      display: none;
    }
  }

}


.workorder-parts-container {
  &.disable {
    pointer-events: none;
    opacity: 0.8;
  }
}

.workorder-labour-container {
  &.disable {
    pointer-events: none;
    opacity: 0.8;
  }
}

.workorder-costs-container {
  max-width: 1338px;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 20px;

  &.disable {
    pointer-events: none;
    opacity: 0.8;
  }

  .costs-partscost {
    grid-row: 1;
    grid-column: 1;
  }

  /* .costs-percentageofparts{
    grid-row: 1;
    grid-column: 2;
  }  */
  .costs-shopsupplies {
    grid-row: 1;
    grid-column: 2;
    display: flex;

    .costs-shopsuppplies-percentage {
      width: 200px;
      height: 50px;
      align-self: flex-end;
    }

    .percentage-of-parts-label {
      margin-left: 5px;
      color: black;
    }
  }

  .costs-labourcost {
    grid-row: 2;
    grid-column: 1;
  }

  .costs-sublet {
    grid-row: 2 / 4;
    grid-column: 2 / 4;

    goa-form-item goa-text-area>div {
      height: 144px;
    }
  }

  .costs-subletcost {
    grid-row: 3;
    grid-column: 1;
  }

  .costs-total {
    grid-row: 4;
    grid-column: 1;
  }

}

.workorder-workby-container {
  max-width: 900px;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 400px 400px;
  row-gap: 20px;
  column-gap: 50px;

  .workby-datein {
    grid-row: 1;
    grid-column: 1;
    &.disable{
      pointer-events: none;
    }
  }

  .workby-createdby {
    grid-row: 1;
    grid-column: 2;
  }

  .workby-workedby {
    grid-row: 2;
    grid-column: 1;
  }

  .workBy-SubGrid-1 {
    grid-row: 2;
    grid-column: 2;
    display: flex;
    column-gap: 20px;

    .workby-certified {
      align-self: flex-end;
    }

    /* .workby-athours{
      grid-row: 2;
      grid-column: 3;
    }
    .workby-atpsi{
      grid-row: 2;
      grid-column: 4;
    }  */
  }

  .workby-dateout {
    grid-row: 3;
    grid-column: 1;

    &.disable {
      opacity: 0.8;
      pointer-events: none;
    }
  }

  .workby-closedby {
    grid-row: 3;
    grid-column: 2;
  }
}

.create-work-order-checkbox {
  margin-top: 45px;
}

.create-work-order-location {
  grid-row: 2;
  grid-column: 1 / 3;
}

.delete-workorder-button {
  margin-left: 35px;

  &.hide {
    display: none;
  }
}

.order-summary-container{
  margin-top: 50px;

  .order-summary-table {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    gap: 10px;
    row-gap: 50px;
    .order-summary-address{
      grid-column: span 2;
    }
    div > :nth-child(1){
        font-weight: bold;
    }
  }
}

.command-bar {
  padding: 120px 0px 110px 0px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: auto !important;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.left {
  float: left;
}

.right {
  float: right;
}

.width-auto {
  width: auto;
}

.width5 {
  width: 5px;
}

.width10 {
  width: 10%;
}

.width20 {
  width: 20%;
}

.width30 {
  width: 30%;
}

.width40 {
  width: 40%;
}

.width50 {
  width: 50%;
}

.width60 {
  width: 60%;
}

.width70 {
  width: 70%;
}

.width80 {
  width: 80%;
}

.width90 {
  width: 90%;
}

.width100 {
  width: 100%;
}

.workorder-unit-no {
  width: 400px;
  font-family: sans-serif;
}

.input-search {
  width: 592px;
}

.input-search-workorders {
  width: 650px;
}

.input-search-adjustments {
  width: 592px;
  padding-top: 2rem;
}

.check-item {
  padding: 1.2rem 0rem 0rem 1rem !important;
}

.import-workorder-button-group {
  position: sticky;
  top: 0;
  right: 0;
  background-color: white;
}

.divTable {
  width: 100%;
  min-height: calc(100vh - 450px);
  overflow-y: hidden;
  overflow-x: auto;
}

.col-50 {
  -ms-flex: 50%;
  /* IE10 */
  flex: 50%;
}

.label .mandatory {
  color: #EC040B !important;
}

.label em {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #EC040B !important;
  margin-left: 5px
}

.input-form-item {
  margin-top: 20px;
}

.pagination {
  margin-top: 30px;
}

.form-row {
  display: flex;
  /* Use flexbox layout */
  justify-content: space-between;
  /* Distribute items evenly along the main axis */
}

.item-space {
  margin-right: 10px;
  /* Add space between items */
}

.item-space-20 {
  margin-right: 150px;
  /* Add space between items */
}

.form-detail-container {
  width: 700px;
  padding-top: 9rem;

  &.work-order-form-container {
    margin-top: -140px;
    width: 100%;
  }

  &.location {
    padding-top: 6rem;
  }
}


.form-buttons-container {
  margin-top: 4rem;
}

.form-detail-view-container {
  width: 700px;

  &.work-order-form-container {
    margin-top: -140px;
    width: 100%;
  }

}


.alt-number-supp {
  width: 500px;
  display: contents;
}

.delete-link {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.input-form-item.right {
  display: flex;
  justify-content: space-between;
}

.input-form-item.left {
  display: flex;
  justify-content: space-between;
}

.input-form-item.right .button-spacing {
  width: 10px;
  /* Adjust this value for desired spacing */
}

.input-form-item.left .button-spacing {
  width: 10px;
  /* Adjust this value for desired spacing */
}



.form-label {
  display: block;
  font-weight: var(--goa-font-weight-bold);
  color: var(--goa-color-text-default);
  font-size: var(--goa-font-size-4);
  padding-bottom: 0.5rem;
}

.view-nav {
  font-weight: bold;
  display: inline;
}

.align-right {
  text-align: right;
}

.adjustment-items {
  width: 1305px;
  height: 625px;
  top: 1022px;
  left: 575px;
  padding: 24px 0px 0px 0px;
  gap: 0px;
  border-radius: 4px 0px 0px 0px;
  border: 1px 0px 0px 0px;
  opacity: 0px;
}

.select-input {
  border: 1px solid var(--goa-color-greyscale-700);
  border-radius: var(--goa-border-radius-m);
  margin-top: 0.3em;
}

input {
  border: 1px solid var(--goa-color-greyscale-700);
  border-radius: var(--goa-border-radius-m);
  height: 41.53px;
}


.input-common {
  width: 10ch;
  padding-left: 10px;
  /* Add left padding */
}

.input-filter {
  width: 20ch;
  padding-left: 10px;
  /* Add left padding */
}

.input-common.enabled {
  background-color: white;
  color: black;
  border: 1px solid initial;
  /* Default border for enabled state */
  pointer-events: initial;
}

.input-common.disabled {
  background-color: #F1F1F1;
  color: #666666;
  border: 1px solid #DCDCDC;
  /* Dark grey border for disabled state */
  pointer-events: none;
}


.css-qbdosj-Input {
  height: 32px;
  border: transparent;
}

.css-13cymwt-control {
  border-style: unset;
}

.css-1nmdiq5-menu {
  background-color: #fff !important;
  ;
  /* Set the background color to white */
  opacity: 1 !important;
  ;
  /* Ensure full opacity */
  z-index: 9999 !important;
  ;
  /* Ensure the menu is above other elements */
}

.MuiInputBase-root .MuiOutlinedInput-root .MuiInputBase-colorPrimary .MuiInputBase-formControl .MuiInputBase-adornedEnd .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  height: 43px !important;
}

.bigtxt-div {
  width: 500px;
}


.form-details-container {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  gap: 8px;
  width: 100%;
  padding: 24px 0px 20px 0px;
}

.form-details-row {
  display: contents;
  /* Allows children to inherit grid properties */
}

.form-details-label {
  font-weight: bold;
  padding: 8px;
  font-size: 0.9em;
  /* smaller font size */
}

.form-details-value {
  padding: 5px;
}

.altNum-supp-row {
  width: 30%;
}

.alt-part-numbers-container {
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  /* Reduced space between rows */
  width: 30%;
}

.alt-row {
  display: flex;
  justify-content: space-between;
  padding: 4px;
  /* Reduced padding */
}

.column {
  flex: 1;
  padding: 4px;
  /* Reduced padding inside columns */
}

.column:first-child {
  flex: 2;
}

.flex {
  display: flex;
}

.location-search {
  width: 700px;
}

.row-button {
  padding-top: 2rem;
}

.interactive {
  display: flex;
  align-items: center;
  /* Vertically centers the content */
  justify-content: center;
  /* Horizontally centers the content (optional) */
  height: 100%;
  /* Ensure this div has a height to center within */
  padding: 24px 100px 20px 10px;
}

.username {
  font-weight: bold;
  /* Example styling */
  color: #333;
  margin-right: 8px;
  /* Adds a bit of margin on the right */
}

.interactive a {
  color: #007bff;
  text-decoration: none;
}

.interactive a:hover {
  text-decoration: underline;
}

.css-clttge-MuiAutocomplete-root .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
  width: 0;
  min-width: 30px;
  height: 12px;
}

.pagination-div {
  margin-top: 100px;
}

.header-menu {
  display: "inline-block";
  padding: 0px 170px 0px 0px;
}

.small-col-width {
  width: 100px;
}

.input-container {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
}

.currency-symbol {
  position: absolute;
  left: 10px;
  color: grey;
  font-size: 16px;
  z-index: 1;
}

.amount-input {
  padding-left: 25px; /* Ensure space for the $ symbol */
  height: 45px;
  width: 100%;
  border-width: 0.05px; /* Make the border even thinner */
  border-radius: 4px;
  font-size: 16px;
  box-sizing: border-box;

  &.catalog-item {
      height: 40px;
      &:hover {
        border-color: var(--goa-color-interactive-hover);
        box-shadow: 0 0 0 var(--goa-border-width-m) var(--goa-color-interactive-hover);
      }
      &:active {
        border-color: var(--goa-color-interactive-focus);
        box-shadow: 0 0 0 var(--goa-border-width-m) var(--goa-color-interactive-focus);
      }
    }
    
    &.workorder-subletcost  {
      height: 40px;
      &:hover {
        border-color: var(--goa-color-interactive-hover);
        box-shadow: 0 0 0 var(--goa-border-width-m) var(--goa-color-interactive-hover);
      }
      &:active {
        border-color: var(--goa-color-interactive-focus);
        box-shadow: 0 0 0 var(--goa-border-width-m) var(--goa-color-interactive-focus);
      }
    }
}

.select-item{
 width: 680px;
}

.centered-cell {
 
  text-align: center;
  padding-top:50px;
}

.order-search-container {
  display: flex;
  gap: 10px; /* Adds space between inputs */
  justify-content: flex-start; /* Aligns inputs to the left */
  width: 60%; /* Restricts the container to half the screen width */
  margin: 20px 0 0 0; /* Adds top margin and resets other margins */
  padding: 0; /* Ensures no extra padding */
}

.order-search-container > div {
  flex: 1; /* Ensures equal size for each input */
}

.order-serach-wapper{
  margin-top: 130px;
  padding-bottom: 380px;
}
